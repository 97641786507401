import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { createCustomerOrder } from "@/api/query/order";
import PayuIcon from "../icons/payou.logo.svg";
import Visa from "../icons/Visa blue.svg";
import MasterCard from "../icons/mastercard.svg";
import Blik from "../icons/blik.svg";
import Transfer from "../icons/przelewy.svg";
import { RegisterContext } from "@/pages/register/context/register.context";
import { PLAN_TYPE } from "@/pages/register/register.constants";

const Title = styled.h3`
  text-align: center;
  color: #05275d;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;

  @media (max-width: 600px) {
    font-size: 22px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1000px;

  @media (max-width: 599px) {
    min-width: 80vw;
    max-width: 90vw;
  }
`;

const Info = styled.div`
  color: #0b2f45;
  width: 100%;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  @media (max-width: 600px) {
    width: 300px;
    text-align: center;
    color: #05275d;
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

const ActionWrapper = styled.form`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 0px 0;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

const LetMailButton = styled.button`
  display: flex;
  width: 200px;
  padding: 15px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  background: #3280fc;
  outline: none;
  border: none;
  cursor: pointer;
  color: #fff;
  /* Button */
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;

  &:hover {
    transform: scale(1.1);
  }
`;

const PricesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const FlexElement = styled.div`
  width: 150px;
  text-align: left;
  padding: 9px 20px 8px 21px;
  justify-content: left;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #d9d9d9;
  background: #fff;
  cursor: pointer;
  display: flex;
  margin: 0 30px;
  color: #05275d;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  gap: 10px;

  @media (min-width: 600px) {
    width: 150px;
    height: 115px;
    margin: 0 5px;
    background: rgba(79, 167, 180, 0.2);
    flex-direction: column;
    justify-content: center;

    gap: 0px;

    .number {
      color: #05275d;
      text-align: center;
      font-family: Work Sans;
      font-size: 64px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 89.6px */
    }

    .month {
      color: #05275d;

      text-align: center;
      font-family: Work Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 28px */
    }
  }
`;

const TextTop = styled.div`
  color: #05275d;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
`;

const TextPrice = styled.p`
  color: #05275d;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0;

  h3 {
    color: #05d698;
    font-family: Work Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.96px;
    margin: 0;
  }
`;

const TextBottom = styled.div`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0px;
`;

const Card = styled.div`
  width: 350px;

  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (min-width: 600px) {
    margin: 0 auto;
    flex-direction: row;
    background-color: #fff;
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    overflow-y: auto;
  }
`;

const PaymentTypeWrapper = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  min-width: 350px;
`;

const PaymentTitle = styled.p`
  color: #05275d;

  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const PaymentText = styled.p`
  color: #05275d;

  text-align: left;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  min-width: 180px;
`;

const Border = styled.div`
  height: 1px;
  width: 100%;
  background: rgba(79, 167, 180, 0.2);
`;

const PaymentElement = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  width: 220px;
  border-radius: 100px;
  background: #3280fc;
  padding: 10px 0;

  color: #fff;

  text-align: center;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`;

const PaymentIcon = styled.img`
  width: 60px;
`;

export const CONFIG_DATA = {
  _1: {
    price: "59,99",
    months: "1 miesiąc",
    full_price: "59,99",
    label: "Bronze",
    type: PLAN_TYPE.BRONZE,
  },
  _2: {
    price: "39,99",
    months: "3 miesiące",
    full_price: "119,97",
    label: "Gold",
    type: PLAN_TYPE.GOLD,
  },
  _3: {
    price: "29,99",
    months: "6 miesięcy",
    full_price: "179,94",
    label: "Platinum",
    type: PLAN_TYPE.PLATINIUM,
  },
};

export const CONFIG_DATA_ARRAY = [
  {
    price: "59,99",
    months: "1 miesiąc",
    full_price: "59,99",
    label: "Bronze",
    type: PLAN_TYPE.BRONZE,
  },
  {
    price: "39,99",
    months: "3 miesiące",
    full_price: "119,97",
    label: "Gold",
    type: PLAN_TYPE.GOLD,
  },
  {
    price: "29,99",
    months: "6 miesięcy",
    full_price: "179,94",
    label: "Platinum",
    type: PLAN_TYPE.PLATINIUM,
  },
];

export const PAYMENT_TYPES = {
  PAYU: "payu",
  APPLE: "apple",
  GOOGLE: "google",
};

export const PAYMENT_FORMS = [
  {
    type: PAYMENT_TYPES.PAYU,
    text: "PayU",
    subText: "Karta płatnicza VISA lub MasterCard",
    icon: PayuIcon,
  },
];

const PickCourseView = () => {
  const [selectedOption, setSelectedOption] = useState(CONFIG_DATA["_1"]);
  const { setView, user, setPlan } = useContext(RegisterContext);

  const handlePickPlanMutation = useMutation({
    mutationFn: (value) => createCustomerOrder(value),
    onSuccess: ({ data }) => {
      localStorage.setItem("register_order", JSON.stringify(data));
      setPlan(data);
      setView(1);
    },
  });

  const handleCreateOrder = (e, type) => {
    e.preventDefault();
    const payload = {};
    payload.user = user._id;
    payload.plan_type = selectedOption.type;
    payload.price =
      parseFloat(selectedOption.full_price.replace(",", ".")) * 100;
    payload.payment_type = type;

    handlePickPlanMutation.mutate(payload);
  };

  return (
    <Wrapper>
      <Card>
        <Title>Wybierz plan dla siebie</Title>
        <Info style={{ textAlign: "center" }}>Dostępne plany:</Info>
        <PricesWrapper>
          <FlexElement
            onClick={() => setSelectedOption(CONFIG_DATA["_1"])}
            style={{
              background:
                selectedOption.type === PLAN_TYPE.BRONZE ? "#3280FC" : "white",
            }}
          >
            <div
              className="number"
              style={{
                color:
                  selectedOption.type === PLAN_TYPE.BRONZE ? "white" : "black",
              }}
            >
              1
            </div>{" "}
            <div
              className="month"
              style={{
                display: "flex",
                gap: "10px",
                color:
                  selectedOption.type === PLAN_TYPE.BRONZE ? "white" : "black",
              }}
            >
              miesiąc
            </div>
          </FlexElement>
          <FlexElement
            onClick={() => setSelectedOption(CONFIG_DATA["_2"])}
            style={{
              background:
                selectedOption.type === PLAN_TYPE.GOLD ? "#3280FC" : "white",
            }}
          >
            <div
              className="number"
              style={{
                color:
                  selectedOption.type === PLAN_TYPE.GOLD ? "white" : "black",
              }}
            >
              3
            </div>{" "}
            <div
              className="month"
              style={{
                display: "flex",
                gap: "10px",
                color:
                  selectedOption.type === PLAN_TYPE.GOLD ? "white" : "black",
              }}
            >
              miesiące{" "}
            </div>{" "}
          </FlexElement>
          <FlexElement
            onClick={() => setSelectedOption(CONFIG_DATA["_3"])}
            style={{
              background:
                selectedOption.type === PLAN_TYPE.PLATINIUM
                  ? "#3280FC"
                  : "white",
              color:
                selectedOption.type === PLAN_TYPE.PLATINIUM ? "white" : "black",
            }}
          >
            <div
              className="number"
              style={{
                color:
                  selectedOption.type === PLAN_TYPE.PLATINIUM
                    ? "white"
                    : "black",
              }}
            >
              6
            </div>{" "}
            <div
              className="month"
              style={{
                display: "flex",
                gap: "10px",
                color:
                  selectedOption.type === PLAN_TYPE.PLATINIUM
                    ? "white"
                    : "black",
              }}
            >
              miesięcy{" "}
              <span style={{ color: "#05d698", fontWeight: "bold" }}>
                (-40%)
              </span>
            </div>{" "}
          </FlexElement>
        </PricesWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {selectedOption.price != "59,99" && (
            <TextTop
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              Zamiast{" "}
              <p
                style={{
                  textDecoration: "1px line-through red",
                  margin: "0",
                }}
              >
                59,99
              </p>{" "}
              zł/mies tylko
            </TextTop>
          )}
          <TextPrice style={{ marginTop: "10px" }}>
            <h3>{selectedOption.price}</h3> zł/mies
          </TextPrice>
          <TextBottom>
            {selectedOption.full_price} zł pobrane co {selectedOption.months}
          </TextBottom>
          <PaymentTypeWrapper
            style={{ marginTop: "80px" }}
            onSubmit={(e) => handleCreateOrder(e, PAYMENT_FORMS[0].type)}
          >
            <PaymentTitle>Metoda płatności PayU:</PaymentTitle>
            <PaymentIcon src={PayuIcon} style={{ width: "70px" }} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                name="Active"
                width={100}
                type="checkbox"
                required
                defaultChecked
              />
              <PaymentText
                style={{
                  borderRadius: "4px",
                  border: "1px solid rgba(79, 167, 180, 0.20)",
                  alignItems: "center",
                  display: "flex",
                  padding: "10px",
                  marginLeft: "10px",
                  gap: "20px",
                }}
              >
                <PaymentIcon src={Blik} style={{ width: "40px" }} />
                <PaymentIcon
                  src={Visa}
                  style={{
                    width: "50px",
                  }}
                />
                <PaymentIcon
                  src={MasterCard}
                  style={{
                    width: "30px",
                  }}
                />
                <PaymentIcon
                  src={Transfer}
                  style={{
                    width: "65px",
                  }}
                />
              </PaymentText>
            </div>
            <PaymentElement id="initiate_checkout" type="submit">
              KUP
            </PaymentElement>
          </PaymentTypeWrapper>
        </div>
      </Card>
    </Wrapper>
  );
};

export default PickCourseView;
