import api from "@/api/api";
import { searchCustomers } from "@/api/query/customer";
import userManager from "@/api/userManager";
import { KEYS_CODES } from "@/common/constants/keyCodes";
import { MessageContext } from "@/common/contexts/MessageContext";
import { getNavigationPath } from "@/common/navigation/getNavigationPath";
import {
  faChartPie,
  faEye,
  faHome,
  faListCheck,
  faSearch,
  faSignOut,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useRef, useState } from "react";
import { useMutation } from "react-query";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import PopUp from "../PopUp";
import NewCustomTable from "../custom/NewCustomTable";
import SaraiLogo from "./images/logo.svg";
import ActiveHome from "./images/active_home.svg";
import InActiveHome from "./images/inactive_home.svg";
import ActiveChart from "./images/active_chart.svg";
import InActiveChart from "./images/inactive_chart.svg";
import ActiveChecklist from "./images/active_checklist.svg";
import InActiveChecklist from "./images/inactive_checklist.svg";
import ActiveUser from "./images/active_user.svg";
import InActiveUser from "./images/inactive_user.svg";
import CustomTable from "../custom/CustomTable";
import Hamburger from "./Hamburger";

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

const { tokenManager } = api;

const Left = styled.div`
  background: white;
  min-width: 80px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Right = styled.div`
  width: 100%;
  height: 100%;
`;

const TopNav = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  height: 60px;
`;

const Title = styled.h2`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin: 0;
`;

const Content = styled.div`
  height: calc(100vh - 60px);
  overflow: auto;
  width: 100%;
`;

const NavElement = styled(Link)`
  position: relative;
  color: #05275d;

  &:hover {
    &:after {
      content: attr(data-tooltip);
      position: absolute;
      top: -30px;
      font-size: 12px;
      left: 50%;
      transform: translateX(-50%);
      background-color: rgba(0, 0, 0, 0.8);
      color: #ffffff;
      padding: 2px 5px;
      border-radius: 5px;
      white-space: nowrap;
      z-index: 999;
    }
  }
`;
const NavElements = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 160px;
  justify-content: flex-start;
  height: 100%;
  align-items: center;
`;

const LocationWrapper = styled.h3`
  display: flex;
  justify-content: center;
  color: #05275d;
  align-items: center;
`;

const SearchWrapper = styled.form`
  cursor: pointer;
  display: flex;
  align-items: center;
  background: white;
  margin: auto 0;
  padding: 5px 10px;
  height: 25px;
  width: 300px;
  border-radius: 10px;

  @media (max-width: 600px) {
    max-width: 150px;
  }
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  margin-left: 10px;

  @media (max-width: 600px) {
    max-width: 150px;
  }
`;

const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #05275d;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 600px) {
    display: none;
  }
`;

const PopUpWrapper = styled.div`
  padding: 40px 10px;
  height: fit-content;
  max-height: 500px;
  overflow: auto;
  color: #05275d;
`;

const ImageWrapperIcon = styled.div`
  border-radius: 4px;
  background: ${(prev) => (prev.isViewActive ? "#3280fc" : "white")};
  box-shadow: 0px 0px 9.4px 0px
    ${(prev) => (prev.isViewActive ? "rgba(5, 39, 93, 0.4)" : "white")};
  display: inline-flex;
  padding: 8px;
  align-items: flex-start;
`;

const Desktop = styled.div`
  display: flex;

  @media (max-width: 600px) {
    display: none;
  }
`;
const Mobile = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: flex;
  }
`;

const NAVIGATION = [
  {
    link: "/admin/home",
    name: "Home",
    icon: faHome,
    active: ActiveHome,
    inActive: InActiveHome,
  },
  {
    link: "/admin/workflow",
    name: "Workflow",
    icon: faListCheck,
    active: ActiveChecklist,
    inActive: InActiveChecklist,
  },
  {
    link: "/admin/reports",
    name: "Reports",
    icon: faChartPie,
    active: ActiveChart,
    inActive: InActiveChart,
  },
  {
    link: "/admin/common",
    name: "Common",
    icon: faUser,
    active: ActiveUser,
    inActive: InActiveUser,
  },
];

const NewAdminLayout = ({ children }) => {
  const [data, setData] = useState();
  const user = userManager?.getUser();
  const location = useLocation();
  const navigate = useNavigate();
  const searchInputRef = useRef();

  const { addMessage } = useContext(MessageContext);

  const searchMutation = useMutation({
    mutationFn: (value) => searchCustomers(value),
    onSuccess: ({ data }) => {
      if (data.length > 0) {
        setData(data);
      } else {
        addMessage("There is no user like that", "error");
      }
    },
  });

  if (!user || "Admin" !== user.role) {
    userManager.removeUser();
    tokenManager.removeToken();

    return <Navigate to={getNavigationPath("/login")} replace />;
  }

  const getLocation = () => {
    const name = location.pathname.split("/")[2];
    return `${name[0].toUpperCase()}${name.slice(1)}`;
  };

  const handleLogout = () => {
    tokenManager.removeToken();
    userManager.removeUser();
    navigate(getNavigationPath("/login"));
  };

  const handleKeyPress = (e) => {
    if (e.key === KEYS_CODES.ENTER) {
      e?.preventDefault();
      handleSearch(e);
    }
  };
  const handleSearch = (e) => {
    e?.preventDefault();

    const payload = {
      search: searchInputRef.current.value,
    };

    searchMutation.mutate(payload);
  };

  const isViewActive = (pathToCheck) => {
    const path = location.pathname;

    if (path.includes(pathToCheck.toLowerCase())) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Wrapper>
        <Left>
          <Title>
            <img src={SaraiLogo} />
          </Title>
          <NavElements>
            {NAVIGATION.map((navElement) => (
              <NavElement to={navElement.link} data-tooltip={navElement.name}>
                <ImageWrapperIcon isViewActive={isViewActive(navElement.name)}>
                  <img
                    src={
                      isViewActive(navElement.name)
                        ? navElement.active
                        : navElement.inActive
                    }
                  />
                </ImageWrapperIcon>
              </NavElement>
            ))}
          </NavElements>
          <NavElement onClick={handleLogout} data-tooltip={"Logout"}>
            <FontAwesomeIcon icon={faSignOut} />
          </NavElement>
        </Left>
        <Right>
          <TopNav>
            <LocationWrapper>{getLocation()}</LocationWrapper>
            <div style={{ display: "flex", gap: "20px" }}>
              <SearchWrapper onSubmit={handleSearch}>
                <SearchInput
                  ref={searchInputRef}
                  type="search"
                  placeholder="Search..."
                  onKeyDown={handleKeyPress}
                  required
                />
                <FontAwesomeIcon
                  icon={faSearch}
                  style={{ color: "#05275d", fontSize: "14px" }}
                  onSubmit={handleSearch}
                />
              </SearchWrapper>
              <Hamburger />
            </div>

            <UserInfoWrapper>{userManager?.getUser()?.email}</UserInfoWrapper>
          </TopNav>
          <Content>{children}</Content>
        </Right>
      </Wrapper>
      {data && (
        <PopUp setClose={setData}>
          <PopUpWrapper>
            <Desktop>
              <NewCustomTable
                headers={["No.", "Name", "Surname", "Email", "Actions"]}
                data={data.map((d, i) => [
                  i + 1,
                  d.name,
                  d.surname,
                  d.email,
                  <div>
                    <Link
                      to={`/admin/customer/stats/${d._id}`}
                      onClick={() => setData(null)}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </Link>
                  </div>,
                ])}
              />
            </Desktop>
            <Mobile>
              <CustomTable
                headers={["No.", "Email", "Actions"]}
                data={data.map((d, i) => [
                  `${i + 1}.`,
                  d.email,
                  <div>
                    <Link
                      to={`/admin/customer/stats/${d._id}`}
                      onClick={() => setData(null)}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </Link>
                  </div>,
                ])}
              />
            </Mobile>
          </PopUpWrapper>
        </PopUp>
      )}
    </>
  );
};

export default NewAdminLayout;
