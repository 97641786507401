import { Color } from "@/common/colors/colors";
import { faBars, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ActiveHome from "./images/active_home.svg";
import InActiveHome from "./images/inactive_home.svg";
import ActiveChart from "./images/active_chart.svg";
import InActiveChart from "./images/inactive_chart.svg";
import ActiveChecklist from "./images/active_checklist.svg";
import InActiveChecklist from "./images/inactive_checklist.svg";
import ActiveUser from "./images/active_user.svg";
import InActiveUser from "./images/inactive_user.svg";
import { Link } from "react-router-dom";

const HamburgerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    display: none;
  }
`;

const MenuItem = styled(Link)`
  margin: 5px;
  cursor: pointer;
  text-decoration: none;
  color: ${Color.Blue};
  display: flex;
  justify-content: left;
  align-items: center;
`;

const MenuIcon = styled.img`
  margin-right: 5px;
  width: 15px;
`;

const Menu = styled.div`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  position: absolute;
  top: 60px; /* Adjust as needed */
  right: 20px;
  border-radius: 15px;
  background-color: white;
  border: 1px solid ${Color.Blue};
  padding: 10px;
`;

const NAVIGATION = [
  {
    link: "/admin/home",
    name: "Home",
    icon: faHome,
    active: ActiveHome,
    inActive: InActiveHome,
  },
  {
    link: "/admin/workflow",
    name: "Workflow",
    active: ActiveChecklist,
    inActive: InActiveChecklist,
  },
  {
    link: "/admin/reports",
    name: "Reports",
    active: ActiveChart,
    inActive: InActiveChart,
  },
  {
    link: "/admin/common",
    name: "Common",
    active: ActiveUser,
    inActive: InActiveUser,
  },
];

const Hamburger = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <HamburgerWrapper>
      <FontAwesomeIcon
        icon={faBars}
        style={{ color: Color.Blue }}
        onClick={toggleMenu}
      />
      <Menu isOpen={isOpen} onClick={closeMenu} ref={menuRef}>
        {NAVIGATION.map((item, index) => (
          <MenuItem to={item.link} key={index}>
            <MenuIcon src={item.inActive} />
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </HamburgerWrapper>
  );
};

export default Hamburger;
