import React, { useState } from "react";
import {
  ButtonBuy,
  MainPlan,
  MarginPlan,
  PlanWrapper,
  Price,
  SubTop,
  Title,
  TopInfo,
  Wrapper,
} from "./planSection.styles";
import BuyPopUp from "./BuyPopUp";
import { clickBuyOnBuyPage } from "@/api/query/lead-history";
import { useMutation } from "react-query";

const PlanSection = () => {
  const [showPopUp, setShowPopUp] = useState();

  const clickBuyOnBuyPageMutation = useMutation({
    mutationFn: (value) => clickBuyOnBuyPage(value),
  });

  const handleBuy = (price) => {
    const payload = {};
    payload._id = localStorage.getItem("lead-history-id");
    payload.price = price;

    clickBuyOnBuyPageMutation.mutate(payload);

    window.gtag("event", "initiate_checkout", { price: price });
    setShowPopUp(price);
  };

  return (
    <>
      {showPopUp && <BuyPopUp setClose={setShowPopUp} price={showPopUp} />}
      <Wrapper id="plan-offer">
        <Title>Wybierz plan dla siebie </Title>
        <PlanWrapper>
          <MarginPlan>
            <TopInfo>1 miesiąc</TopInfo>
            <Price>99,99 zł</Price>
            <ButtonBuy
              id="initiate_checkout"
              style={{}}
              onClick={() => handleBuy("99,99")}
            >
              KUP TERAZ
            </ButtonBuy>
          </MarginPlan>
          <MainPlan>
            <div>
              <TopInfo>3 miesiące</TopInfo>
              <SubTop>najczęściej wybierana oferta</SubTop>
            </div>
            <div>
              <Price>159,99 zł</Price>
              <SubTop>oszczędzasz 50%</SubTop>
            </div>
            <ButtonBuy
              id="initiate_checkout"
              style={{ marginTop: "10px" }}
              onClick={() => handleBuy("159,99")}
            >
              KUP TERAZ
            </ButtonBuy>
          </MainPlan>
          <MarginPlan>
            <TopInfo style={{ marginTop: "10px" }}>6 miesięcy</TopInfo>
            <SubTop>najlepsza oferta!</SubTop>
            <Price style={{ marginTop: "30px" }}>239,99 zł</Price>
            <SubTop>oszczędzasz 60%</SubTop>
            <ButtonBuy
              id="initiate_checkout"
              style={{ marginTop: "20px" }}
              onClick={() => handleBuy("239,99")}
            >
              KUP TERAZ
            </ButtonBuy>
          </MarginPlan>
        </PlanWrapper>
      </Wrapper>
    </>
  );
};

export default PlanSection;
